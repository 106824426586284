import { Button, ClickAwayListener, Drawer, Paper, Popper } from "@mui/material"

import { MouseEvent, useState } from "react"
import { useWallet } from "../Providers/WalletProvider"
import { useBreakpoints } from "../Providers/ThemeRegistry/BreakpointsProvider"

import WalletMenu from "./WalletMenu"
import { NetworkProviderIcon } from "./NetworkProviderIcon"

const WalletMenuButton = () => {
  const { disconnect, address, isLoading } = useWallet()

  const { md } = useBreakpoints()

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (!address || isLoading) {
      return
    }
    if (md.up) {
      setAnchorEl(event.currentTarget)
    } else {
      setIsDrawerOpen(true)
    }
  }

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const handleClose = () => {
    setAnchorEl(null)
  }

  const onLogOut = () => {
    setAnchorEl(null) // closes popover
    setTimeout(disconnect, 1)
  }

  return (
    <>
      <Button
        onClick={(e) => handleClick(e)}
        sx={{ p: 0, cursor: "pointer", "&:hover": { backgroundColor: "transparent" }, minWidth: "35px" }}
      >
        {WalletAvatar}
      </Button>
      {md.up && (
        <Popper
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          placement={md.up ? "bottom-end" : "bottom"}
          sx={{ zIndex: 4 }}
          disablePortal
        >
          <ClickAwayListener onClickAway={handleClose}>
            <Paper
              sx={{ width: "320px", border: (theme) => `1px solid ${theme.palette.divider}`, borderRadius: "8px" }}
            >
              <WalletMenu logOut={onLogOut} setIsWalletMenuOpen={setIsDrawerOpen} />
            </Paper>
          </ClickAwayListener>
        </Popper>
      )}
      {md.down && (
        <Drawer
          anchor="bottom"
          open={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          PaperProps={{ sx: { borderTopLeftRadius: "16px", borderTopRightRadius: "16px" } }}
        >
          <WalletMenu logOut={onLogOut} setIsWalletMenuOpen={setIsDrawerOpen} />
        </Drawer>
      )}
    </>
  )
}

export default WalletMenuButton

export const WalletAvatar = <NetworkProviderIcon size="large" />
