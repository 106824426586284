import logger from "@/utils/logger"
import { createContext, useCallback, useContext, useMemo, useState } from "react"
import { useEnsName, useSwitchNetwork } from "wagmi"
import { Address } from "viem"
import { WalletConnector, useDynamicContext } from "@dynamic-labs/sdk-react-core"

export interface WalletContextValue {
  address?: Address | string
  ens?: string
  connector?: WalletConnector
  name?: string
  walletIcon?: string
  connect: () => void
  disconnect: () => Promise<void>
  switchNetwork: (chainId: number) => Promise<void>
  isLoading: boolean
  isConnected: boolean
  chain?: string
  network?: string | number
  signMessage?: (message: string) => Promise<string | undefined>
}

const WalletContext = createContext<WalletContextValue>({
  connect: () => {},
  disconnect: async () => {},
  switchNetwork: async () => {},
  isLoading: false,
  isConnected: false,
})

export const WalletProvider = ({ children }: { children: React.ReactNode }) => {
  const [isLoading, setIsLoading] = useState(false)

  const { primaryWallet, setShowAuthFlow, handleLogOut } = useDynamicContext()

  // Get ENS name if on an EVM network
  const { data: ens } = useEnsName({
    address: primaryWallet?.address as Address,
    enabled: !!primaryWallet?.address && primaryWallet?.network === 1,
  })

  // Wagmi methods
  const { switchNetworkAsync } = useSwitchNetwork()

  const connect = () => {
    setShowAuthFlow(true)
  }

  const disconnect = useCallback(async () => {
    await handleLogOut()
  }, [])

  const switchNetwork = async (chainId: number) => {
    setIsLoading(true)
    try {
      await switchNetworkAsync!(chainId)
    } catch (error) {
      logger.error("Error switching network", error)
    }
    setIsLoading(false)
  }

  const walletIcon = useMemo(() => {
    if (!primaryWallet) return undefined
    const spriteId = primaryWallet.connector.walletBook.wallets[primaryWallet.connector.key].brand?.spriteId
    return `https://iconic.dynamic-static-assets.com/icons/sprite.svg#${spriteId}`
  }, [primaryWallet])

  const state: WalletContextValue = {
    // Seems like primaryWallet.address can be "undefined" as a string (happened at least once), so handling this here
    address: primaryWallet && primaryWallet.address !== "undefined" ? (primaryWallet.address as Address) : undefined,
    ens: ens ?? undefined,
    chain: primaryWallet?.chain,
    network: primaryWallet?.network,
    connector: primaryWallet?.connector,
    connect,
    disconnect,
    switchNetwork,
    isLoading,
    name: primaryWallet?.connector?.name,
    signMessage: primaryWallet?.connector?.signMessage.bind(primaryWallet?.connector),
    isConnected: !isLoading && !!primaryWallet?.address,
    walletIcon,
  }

  return <WalletContext.Provider value={state}>{children}</WalletContext.Provider>
}

const useNetwork = () => {
  const chain = useContext(WalletContext).chain
  const network = useContext(WalletContext).network

  if (chain === "SOL") return "Solana"

  switch (network) {
    case 1:
      return "Ethereum"
    case 5000:
      return "Mantle"
    default:
      return "Unknown"
  }
}

export const useWallet = () => {
  const walletContext = useContext(WalletContext)
  return {
    ...walletContext,
    network: useNetwork(),
  }
}
