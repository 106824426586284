export const cookieOptions = [
  {
    key: "strictly_necessary",
    name: "Strictly Necessary Cookies",
    details:
      "These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site will not then work. These cookies do not store any personally identifiable information.",
    alwaysOn: true,
  },
  {
    key: "has_accepted_functional",
    name: "Functional Cookies",
    details:
      "These cookies enable the website to provide enhanced functionality and personalization. They may be set by us or by third party providers whose services we have added to our pages. If you do not allow these cookies then some or all of these services may not function properly.",
  },
  {
    key: "has_accepted_analytics",
    name: "Analytics Cookies",
    details:
      "These cookies allow us to count visits and traffic sources, so we can measure and improve the performance of our site. They help us know which pages are the most and least popular and see how visitors move around the site. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies, we will not know when you have visited our site.",
  },
  {
    key: "has_accepted_targeting",
    name: "Targeting Cookies",
    details:
      "Targeting cookies may be set through our site by our advertising partners. They can be used by these third parties to build a profile of your interests based on the browsing information they collect from you, which includes uniquely identifying your browser and terminal equipment. If you do not allow these cookies you will still see basic advertising on your browser that is generic and not based on your interests.",
  },
];
