"use client"
import { useEffect } from "react"

import ls from "@/utils/localStorage"
import { Network } from "@/generated/prisma/ondo_foundation"
import { saveWalletEvent } from "@/app/actions"
import { useWallet } from "../Providers/WalletProvider"

const ONE_HOUR = 60 * 60 * 1000

const WalletEventsTracker = () => {
  const { network, address } = useWallet()
  const connectedWallet = ls.get("connected-wallet-data")
  const isWalletConnected = !!address
  const noWalletStored = !connectedWallet.address

  useEffect(() => {
    const walletStoreExpired = connectedWallet?.expiresAt < Date.now()
    const networkUpper = network.toUpperCase()
    const connectedAddressUpdated = connectedWallet?.address !== address || connectedWallet?.network !== networkUpper
    const networkIsKnown = networkUpper !== "UNKNOWN"
    const shouldUpdateStore =
      networkIsKnown && isWalletConnected && (noWalletStored || walletStoreExpired || connectedAddressUpdated)

    let handler: NodeJS.Timeout

    if (shouldUpdateStore) {
      handler = setTimeout(() => {
        saveWalletEvent(`${address}`, networkUpper as Network)
        ls.set("connected-wallet-data", {
          address: `${address}`,
          expiresAt: Date.now() + ONE_HOUR,
          network: networkUpper,
        })
      }, 500)
    }

    return () => {
      clearTimeout(handler)
    }
  }, [address, connectedWallet])
  return null
}

export default WalletEventsTracker
