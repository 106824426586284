"use client"
import Button from "@mui/material/Button/Button"
import Dialog from "@mui/material/Dialog/Dialog"
import Divider from "@mui/material/Divider/Divider"
import Paper from "@mui/material/Paper/Paper"
import Stack from "@mui/material/Stack/Stack"
import Typography from "@mui/material/Typography/Typography"
import { styled } from "@mui/material/styles"

import { CookieAccordion } from "./CookieAccordion"
import { useEffect, useState } from "react"
import { cookieOptions } from "./CookieOptions"
import ls from "../../utils/localStorage"
import { CookiePreference } from "@/types/cookies"
import useGAMeasurement from "@/hooks/useGAMeasurement"

type CookieOption = {
  key: keyof CookiePreference
  name: string
  details: string
  alwaysOn?: boolean
}
const CookiesModule = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [activeAccordion, setActiveAccordion] = useState<string | undefined>(undefined)
  const [cookiePrefs, setCookiePrefs] = useState<CookiePreference>({
    has_accepted_functional: false,
    has_accepted_analytics: false,
    has_accepted_targeting: false,
    initial: true,
  })
  const [hasSetPreferences, setHasSetPreferences] = useState(true)
  useGAMeasurement(cookiePrefs.has_accepted_analytics)
  useEffect(() => {
    const saved = ls.get("cookie-preferences")
    if (saved?.date) {
      setCookiePrefs(saved)
    } else {
      setHasSetPreferences(false)
    }
  }, [])

  const allowAll = () => {
    const all = {
      has_accepted_functional: true,
      has_accepted_analytics: true,
      has_accepted_targeting: true,

      date: Date.now(),
    }
    ls.set("cookie-preferences", all)
    setCookiePrefs(all)
    setIsOpen(false)
    setHasSetPreferences(true)
  }

  // accepted and necessary (defaults to necessary)
  const allowSelected = () => {
    const selected = { ...cookiePrefs, date: Date.now(), initial: false }
    ls.set("cookie-preferences", selected)
    setCookiePrefs(selected)
    setIsOpen(false)
    setHasSetPreferences(true)
  }

  // necessary only
  const necessaryOnly = () => {
    const necessary = {
      has_accepted_functional: false,
      has_accepted_analytics: false,
      has_accepted_targeting: false,

      date: Date.now(),
    }
    ls.set("cookie-preferences", necessary)
    setCookiePrefs(necessary)
    setIsOpen(false)
    setHasSetPreferences(true)
  }
  if (hasSetPreferences) {
    return null
  }

  return (
    <>
      {!hasSetPreferences && (
        <CookiePopover data-testid="cookie-popover">
          <Typography>This site uses cookies to personalise your experience.</Typography>
          <Stack sx={{ marginTop: { xs: "30px", md: "15px" } }} direction={{ xs: "column", sm: "row" }} spacing={1.5}>
            <Button variant="contained" onClick={allowAll} data-testid="allow-all-cookies-button">
              Allow All
            </Button>
            <Button variant="outlined" onClick={necessaryOnly}>
              Allow Necessary
            </Button>
            <Button variant="outlined" onClick={() => setIsOpen(true)} data-testid="manage-cookies-button">
              Manage
            </Button>
          </Stack>
        </CookiePopover>
      )}

      <Dialog
        PaperProps={{ sx: { padding: 0, margin: 1 } }}
        open={isOpen}
        maxWidth="sm"
        onClose={() => setIsOpen(false)}
        data-testid="cookies-prefs-dialog"
      >
        <Typography align="center" variant="h6" pt={4} pb={1}>
          Cookie Preferences
        </Typography>
        <Typography align="center" variant="body2" pb={3}>
          This site uses cookies to personalise your experience.
        </Typography>

        <Divider sx={{ mb: 0 }} />

        {(cookieOptions as CookieOption[]).map((cookieOption, i) => (
          <CookieAccordion
            key={cookieOption.key}
            alwaysOn={cookieOption.alwaysOn}
            name={cookieOption.name}
            details={cookieOption.details}
            onExpand={() =>
              cookieOption.key === activeAccordion
                ? setActiveAccordion(undefined)
                : setActiveAccordion(cookieOption.key)
            }
            expanded={cookieOption.key === activeAccordion}
            enabled={!!cookiePrefs?.[cookieOption.key]}
            onChange={(checked) => setCookiePrefs({ ...cookiePrefs, [cookieOption.key]: checked })}
          />
        ))}

        <Stack direction={{ xs: "column", sm: "row" }} spacing={1} p={2}>
          <Button variant="outlined" onClick={allowSelected} data-testid="confirm-cookies-prefs-button">
            Confirm my choices
          </Button>
          <Button variant="contained" onClick={allowAll} data-testid="allow-all-cookies-button">
            Allow All
          </Button>
        </Stack>
      </Dialog>
    </>
  )
}

export const CookiePopover = styled(Paper)(({ theme }) => ({
  position: "fixed",
  padding: "20px",
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 100,
  [theme.breakpoints.down("sm")]: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  [theme.breakpoints.up("sm")]: {
    bottom: theme.spacing(3),
    right: "unset",
    left: theme.spacing(3),
    maxWidth: "27rem",
    width: "100%",
  },
}))

export default CookiesModule
