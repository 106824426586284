"use client"
import Typography from "@mui/material/Typography/Typography"
import Link from "next/link"
import { Discord, ExternalLink, Telegram, Twitter } from "../svgs/Svgs"
import { Button, Stack, useMediaQuery, useTheme } from "@mui/material"
import React, { Fragment } from "react"
import { MobileNavDropdownMenu } from "./MobileNavDropdownMenu"
import { DesktopNavDropdownMenu } from "./DesktopNavDropdownMenu"
import { NavLinks } from "./NavLinks"

type Props = {
  closeHeaderMenu?: () => void
}

const HeaderLinks: React.FC<Props> = ({ closeHeaderMenu = () => {} }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"))
  return (
    <>
      {NavLinks.map((link) => {
        if (link.subNavLinks) {
          return (
            <Fragment key={link.name}>
              {isMobile ? (
                <MobileNavDropdownMenu
                  closeHeaderMenu={closeHeaderMenu}
                  subNavLinks={link.subNavLinks}
                  label={link.name}
                />
              ) : (
                <DesktopNavDropdownMenu subNavLinks={link.subNavLinks} label={link.name} />
              )}
            </Fragment>
          )
        }
        return (
          <Button
            component={Link}
            key={link.name}
            href={link.href}
            onClick={closeHeaderMenu}
            target={link.openInNewTab ? "_blank" : undefined}
            sx={{ px: isMobile ? 0 : 1.4, py: isMobile ? 0 : 0, background: "inherit !important", minWidth: "unset" }}
          >
            <Stack direction="row" alignItems="center" spacing={0.2}>
              <Typography
                onClick={closeHeaderMenu}
                variant={isMobile ? "h6" : "body1"}
                sx={{ lineHeight: "28px !important" }}
              >
                {link.name}
              </Typography>
              {link.showArrow && <ExternalLink />}
            </Stack>
          </Button>
        )
      })}
    </>
  )
}

export const SocialLinks = () => {
  return (
    <>
      <Link href="https://twitter.com/ondofoundation" target="_blank" passHref>
        <Twitter />
      </Link>
      <Link href="https://discord.ondo.foundation" target="_blank" passHref>
        <Discord />
      </Link>
      <Link href="https://t.me/ondocommunity" target="_blank" passHref>
        <Telegram />
      </Link>
    </>
  )
}

export default HeaderLinks
