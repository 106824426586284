import { useState } from "react"

import { colors } from "../Providers/ThemeRegistry/theme"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { shortenAddress } from "@/utils/wallet.utils"
import { useWallet } from "../Providers/WalletProvider"
import { WalletAvatar } from "./WalletMenuButton"
import Divider from "@mui/material/Divider"

import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew"
import LaunchIcon from "@mui/icons-material/Launch"
import { useGetChainExplorer } from "./getChainExplorer"
import React from "react"
import Box from "../Box"

export const WalletMenu = ({
  logOut,
  setIsWalletMenuOpen,
}: {
  logOut: () => void
  setIsWalletMenuOpen: (_: boolean) => void
}) => {
  const { address, network, ens } = useWallet()

  const [copyText, setCopyText] = useState("Copy Address")

  const [hasCopied, setHasCopied] = useState(false)
  const handleCopy = () => {
    navigator.clipboard.writeText(address!)
    if (hasCopied === false) {
      setTimeout(() => {
        setCopyText("Copy Address")
        setHasCopied(false)
      }, 600)
    }
    setCopyText("Copied!")
    setHasCopied(true)
  }

  const chainExplorer = useGetChainExplorer()
  const explorerUrl = `${chainExplorer}/address/${address}`
  const navMenuItems = [
    {
      title: copyText,
      icon: <ContentCopyIcon style={{ height: "20px", width: "20px" }} />,
      onClick: handleCopy,
    },
    ...(!!chainExplorer
      ? [
          {
            title: "View on Explorer",
            icon: <LaunchIcon style={{ height: "20px", width: "20px" }} />,
            linkUrl: explorerUrl,
            isExternalLink: true,
          },
        ]
      : []),
    {
      title: "Disconnect",
      icon: <PowerSettingsNewIcon style={{ height: "20px", width: "20px", color: colors.red.main }} />,
      color: colors.red.main,
      onClick: () => {
        logOut()
        setIsWalletMenuOpen(false)
      },
    },
  ]

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" px={1} spacing={0.2}>
        <Stack
          direction="row"
          alignItems="center"
          p={1.2}
          spacing={1}
          sx={{ flex: "1 1 0%", position: "relative", overflow: "hidden" }}
        >
          {WalletAvatar}
          <Stack sx={{ flex: 1, overflow: "hidden" }}>
            <Typography
              variant="body2"
              color={colors.base.greyMid2}
              sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
            >
              {ens ? ens : shortenAddress(address)!}
            </Typography>
            {!!network && (
              <Typography variant="body2" color={colors.base.greyMid2}>
                {network == "Unknown" ? "Unsupported Network" : network}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Stack>
      <Divider sx={{ mx: 1.2 }} />
      <Stack py={"10px"} p={1} spacing={0.2}>
        {navMenuItems.map((menuItem) => (
          <React.Fragment key={menuItem.title}>
            {menuItem.linkUrl && (
              <a href={menuItem.linkUrl} target="_blank" style={{ color: "inherit", textDecoration: "none" }}>
                <InnerNavMenuItem
                  key={menuItem.title}
                  title={menuItem.title}
                  color={menuItem.color}
                  icon={menuItem.icon}
                  onClick={menuItem.onClick}
                />
              </a>
            )}
            {!menuItem.linkUrl && (
              <InnerNavMenuItem
                key={menuItem.title}
                title={menuItem.title}
                color={menuItem.color}
                icon={menuItem.icon}
                onClick={menuItem.onClick}
              />
            )}
          </React.Fragment>
        ))}
      </Stack>
    </>
  )
}

type InnerNavMenuItemProps = {
  icon: React.ReactNode
  title: string
  color?: string
  onClick?: () => void
}

const InnerNavMenuItem = ({ icon, title, color, onClick }: InnerNavMenuItemProps) => (
  <Box
    sx={{
      "&:hover": {
        "&.MuiBox-root": {
          backgroundColor: "action.hover",
          borderRadius: "8px",
        },
      },
      cursor: "pointer",
    }}
    onClick={!!onClick ? onClick : () => {}}
  >
    <Stack
      direction="row"
      justifyContent="flex-start"
      sx={{
        padding: "8px 12px",
        alignItems: "center",
      }}
    >
      {icon}
      <Typography sx={{ ml: "6px", color: color ? color : undefined }}>{title}</Typography>
    </Stack>
  </Box>
)

export default WalletMenu
