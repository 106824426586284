import { useWallet } from "../Providers/WalletProvider"

const ChainExplorers = {
  SOLANA: "https://solscan.io",
  ETHEREUM: "https://etherscan.io",
  MANTLE: "https://explorer.mantle.xyz",
} as const

type ChainExplorerId = keyof typeof ChainExplorers

export const useGetChainExplorer = (): string | undefined => {
  const { network } = useWallet()
  const networkName = network.toUpperCase() as ChainExplorerId

  return ChainExplorers[networkName]
}
