import Button from "@mui/material/Button"
import { useWallet } from "../Providers/WalletProvider"
import { SxProps } from "@mui/material"

const ConnectButton = ({ sx, variant = "contained" }: { variant?: "outlined" | "contained"; sx?: SxProps }) => {
  const { connect, disconnect, isLoading, isConnected } = useWallet()
  const handleClick = () => {
    if (!isConnected) {
      connect()
    } else {
      disconnect()
    }
  }

  return (
    <Button
      disabled={isLoading}
      variant={variant}
      sx={{ size: { xs: "small", md: "medium" }, ...sx }}
      fullWidth
      onClick={handleClick}
    >
      {!isConnected ? `Connect Wallet` : "Disconnect"}
    </Button>
  )
}
export default ConnectButton
