"use client"

import * as React from "react"
import { ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"

import NextAppDirEmotionCacheProvider from "./EmotionCache"
import { lightTheme } from "./theme"
import { BreakpointsProvider } from "@/components/Providers/ThemeRegistry/BreakpointsProvider"

export default function ThemeRegistry({ children }: { children: React.ReactNode }) {
  return (
    <NextAppDirEmotionCacheProvider options={{ key: "mui" }}>
      <ThemeProvider theme={lightTheme}>
        <BreakpointsProvider>
          <CssBaseline />
          {children}
        </BreakpointsProvider>
      </ThemeProvider>
    </NextAppDirEmotionCacheProvider>
  )
}
