import Container from "@mui/material/Container/Container"

import { OndoFoundationLogo } from "../svgs/Svgs"
import Link from "next/link"
import Box from "@/components/Box"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/material/styles"
import { colors } from "@/components/Providers/ThemeRegistry/theme"
import { MouseEventHandler } from "react"

const HeaderContainer: React.FC<{ borderBottom?: string; children: React.ReactNode }> = ({
  borderBottom = "",
  children,
}) => {
  return (
    <Box style={{ width: "100%", position: "absolute", borderBottom }}>
      <HeaderLogo>{children}</HeaderLogo>
    </Box>
  )
}

export const HeaderLogo: React.FC<{ children: React.ReactNode; onClick?: MouseEventHandler }> = ({
  children,
  onClick,
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))
  return (
    <Container
      maxWidth="xl"
      sx={{
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        height: isMobile ? "48px" : "72px",
        zIndex: 1,
        width: "100%",
        maxWidth: "1600px",
        position: "relative",
        top: 0,
      }}
    >
      <Link href="/" style={{ lineHeight: 0 }} onClick={onClick}>
        <OndoFoundationLogo
          height={isMobile ? "40px" : "64px"}
          width={isMobile ? "109px" : "172px"}
          color={colors.base.black}
        />
      </Link>
      {children}
    </Container>
  )
}

export default HeaderContainer
