"use client"

import { useEffect } from "react"
import dynamicIntercepts from "./dynamicInterceptors"

const RequestInterceptor = () => {
  const intercepts = dynamicIntercepts

  useEffect(() => {
    // Intercept fetch requests
    const { fetch: originalFetch } = window
    window.fetch = async (...args) => {
      let [resource, config] = args
      // Check intercepts
      for (let i = 0; i < intercepts.length; i++) {
        const intercept = intercepts[i]
        if (await intercept.shouldIntercept(resource as string, config)) {
          return intercept.intercept()
        }
      }
      // Return the original response if no intercepts are matched
      return originalFetch(resource, config)
    }
  }, [])

  return null
}

export default RequestInterceptor
