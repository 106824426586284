import { PointsWalletAuth } from "@/components/Points/types"
import { CookiePreference } from "@/types/cookies"

type LocalStorageItem =
  | { name: "session-token"; data: string }
  | { name: "points-wallet-auth"; data: PointsWalletAuth }
  | { name: "points-viewed-welcome"; data: boolean }
  | { name: "cookie-preferences"; data: CookiePreference }
  | { name: "connected-wallet-data"; data: { address: string; expiresAt: number; network: string } }

const get = <T extends LocalStorageItem["name"]>(
  key: T,
  defaultValue: any = {}
): Extract<LocalStorageItem, { name: T }>["data"] => {
  const data = localStorage.getItem(key)

  try {
    return JSON.parse(data as string) ?? defaultValue
  } catch {
    return data ?? defaultValue
  }
}

const set = <T extends LocalStorageItem["name"]>(key: T, data: Extract<LocalStorageItem, { name: T }>["data"]) => {
  localStorage.setItem(key, JSON.stringify(data))
}

const remove = (key: LocalStorageItem["name"]) => {
  localStorage.removeItem(key)
}

export default {
  get,
  set,
  remove,
}
