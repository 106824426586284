import(/* webpackMode: "eager" */ "__barrel_optimize__?names=Box!=!/vercel/path0/node_modules/@mui/material/index.js");
import(/* webpackMode: "eager" */ "__barrel_optimize__?names=Stack!=!/vercel/path0/node_modules/@mui/material/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/Hydrate.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/isRestoring.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/QueryClientProvider.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/QueryErrorResetBoundary.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/reactBatchedUpdates.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/useInfiniteQuery.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/useIsFetching.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/useIsMutating.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/useMutation.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/useQueries.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@tanstack/react-query/build/lib/useQuery.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/CookiesModule/CookiesModule.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/GlobalFooter.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Header/DesktopHeader.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Header/MobileHeader.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Providers/NextAuthProvider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Providers/ReactQueryProvider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Providers/ThemeRegistry/ThemeRegistry.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Providers/WalletWrapper.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/WalletEvents/WalletEvents.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/utils/request-interceptor/RequestInterceptor.tsx")