/* eslint-disable no-console */

import * as Sentry from "@sentry/nextjs"

//This logger serves to ensure that we are always capturing sentry exceptions
//an eslint rule is in place to disallow any use of console.error except for this file
const logger = (() => {
  const Logger = (type: string, ...messages: any[]) => {
    switch (type) {
      case "info":
        console.info(...messages)
        // Sentry.captureMessage(messages.toString())
        break
      case "warn":
        console.warn(...messages)
        break
      case "error":
        console.error(...messages)
        try {
          Sentry.captureException(messages.toString())
        } catch (error) {
          // just in case messages is invalid
        }
        break
      case "trace":
        console.trace(...messages)
        break
      case "debug":
      default:
        console.log(...messages)
    }
  }

  return {
    debug: Logger.bind(null, "debug"),
    info: Logger.bind(null, "info"),
    warn: Logger.bind(null, "warn"),
    error: Logger.bind(null, "error"),
    trace: Logger.bind(null, "trace"),
  }
})()

export default logger
