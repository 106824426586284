import { usePathname } from "next/navigation"
import { useEffect, useState } from "react"
import ReactGA from "react-ga4"

const useGAMeasurement = (accepted: boolean) => {
  const [didInit, setDidInit] = useState(false)
  const pathname = usePathname()
  useEffect(() => {
    if (accepted && !didInit && process.env.NEXT_PUBLIC_GA_ID !== undefined) {
      ReactGA.initialize(process.env.NEXT_PUBLIC_GA_ID!)
      setDidInit(true)
    }
    if (accepted) {
      ReactGA.send({ hitType: "pageview", page: pathname })
    }
  }, [accepted, pathname])
}

export default useGAMeasurement
