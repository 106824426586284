export type NavElement = NavLink | NavDropdown
export type NavDropdown = {
  name: string
  subNavLinks: NavLink[]
}
export type NavLink = {
  name: string
  href: string
  showArrow?: boolean
  openInNewTab?: boolean
  subNavLinks?: undefined
}

export const NavLinks: NavElement[] = [
  { name: "About", href: "/about" },
  { name: "Blog", href: "https://blog.ondo.foundation", openInNewTab: true, showArrow: true },
  { name: "Flux", href: "https://fluxfinance.com", openInNewTab: true, showArrow: true },
  {
    name: "Governance",
    subNavLinks: [
      { name: "Ondo DAO", href: "https://docs.ondo.foundation/ondo-dao", openInNewTab: true },
      { name: "Ondo Token", href: "https://docs.ondo.foundation/ondo-token", openInNewTab: true },
      { name: "Vote", href: "https://www.tally.xyz/gov/ondo-dao", openInNewTab: true, showArrow: true },
      { name: "Discuss", href: "https://forum.fluxfinance.com/", openInNewTab: true, showArrow: true },
    ],
  },
  {
    name: "Points",
    subNavLinks: [
      { name: "Get Points", href: "/points/campaigns" },
      { name: "Leaderboard", href: "/points/leaderboard" },
    ],
  },
]
