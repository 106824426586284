"use client"
import { useEffect, useState } from "react"
import HeaderContainer, { HeaderLogo } from "./HeaderContainer"

import IconButton from "@mui/material/IconButton/IconButton"
import { Close, Menu } from "@mui/icons-material"
import Box from "@/components/Box"

import Stack from "@mui/material/Stack/Stack"
import HeaderLinks, { SocialLinks } from "./HeaderLinks"
import { colors } from "../Providers/ThemeRegistry/theme"
import useShowBorder from "./useShowBorder"
import ConnectButton from "../ConnectButton/ConnectButton"
import { useWallet } from "../Providers/WalletProvider"
import WalletMenuButton from "../WalletMenu/WalletMenuButton"

const MobileHeader = () => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const showBorder = useShowBorder()
  useEffect(() => {
    if (menuIsOpen === true) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = ""
    }
  }, [menuIsOpen])
  const { isConnected } = useWallet()

  return (
    <>
      {!menuIsOpen && (
        <HeaderContainer borderBottom={showBorder ? `1px solid ${colors.base.greyLight}` : "none"}>
          <Stack direction="row" alignItems="center" gap="8px">
            {!isConnected ? <ConnectButton /> : <WalletMenuButton />}
            <IconButton
              onClick={() => setMenuIsOpen((isOpen) => !isOpen)}
              data-testid="menu-button"
              disableRipple
              size="small"
            >
              <Menu />
            </IconButton>
          </Stack>
        </HeaderContainer>
      )}
      <Box
        sx={{
          position: "fixed",
          width: "100%",
          height: menuIsOpen ? "100%" : 0,
          background: colors.base.white,
          zIndex: 1,
          overflow: "hidden",
          transition: "height 0.3s ease-in-out",
        }}
      >
        <HeaderLogo onClick={() => setMenuIsOpen(false)}>
          <IconButton
            onClick={() => setMenuIsOpen((isOpen) => !isOpen)}
            data-testid="menu-button"
            disableRipple
            size="small"
          >
            <Close />
          </IconButton>
        </HeaderLogo>
        <Stack gap="30px" paddingLeft="30px">
          <Stack sx={{ paddingTop: "18px" }} alignItems="start" gap="16px">
            <HeaderLinks closeHeaderMenu={() => setMenuIsOpen(false)} />
          </Stack>
          <Stack alignItems="center" flexDirection="row" gap="18px">
            <SocialLinks />
          </Stack>
        </Stack>
      </Box>
    </>
  )
}

export default MobileHeader
