"use client"

import Stack from "@mui/material/Stack/Stack"
import HeaderLinks, { SocialLinks } from "./HeaderLinks"
import HeaderContainer from "./HeaderContainer"
import { colors } from "../Providers/ThemeRegistry/theme"
import useShowBorder from "./useShowBorder"

import ConnectButton from "../ConnectButton/ConnectButton"
import WalletMenuButton from "../WalletMenu/WalletMenuButton"
import { useWallet } from "../Providers/WalletProvider"

const DesktopHeader = () => {
  const showBorder = useShowBorder()
  const { isConnected } = useWallet()
  return (
    <HeaderContainer borderBottom={showBorder ? `1px solid ${colors.base.greyLight}` : "none"}>
      <Stack
        sx={{
          inset: "50%",
          position: "absolute",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
          gap: "2px",
        }}
      >
        <HeaderLinks />
      </Stack>
      {/* Note: 153px below is the width of the logo on the left-side of the header. We need to add this minWidth to center the header links. */}
      <Stack direction="row" alignItems="center" justifyContent="flex-end" gap="18px" minWidth="153px">
        {!isConnected ? <ConnectButton /> : <WalletMenuButton />}
        <SocialLinks />
      </Stack>
    </HeaderContainer>
  )
}

export default DesktopHeader
