import Box from "../Box"
import { colors } from "../Providers/ThemeRegistry/theme"
import { useWallet } from "../Providers/WalletProvider"

export const NetworkProviderIcon = ({ size = "small" }: { size?: "small" | "large" }) => {
  const { address, network } = useWallet()

  const { walletIcon } = useWallet()

  if (!address) {
    return null
  }

  const networkIconSrc = getIconSrc(network.toUpperCase() as keyof typeof icons)
  return (
    <Box
      sx={{
        backgroundColor: colors.base.offWhite,
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "8px",
      }}
      width={size === "small" ? "32px" : "40px"}
      height={size === "small" ? "32px" : "40px"}
    >
      <NetworkProviderInnerIcon walletIcon={walletIcon!} size={size} />
      <img
        src={networkIconSrc}
        alt="wallet icon"
        style={{
          borderRadius: "20px",
          position: "absolute",
          right: "3px",
          top: "3px",
          width: size === "small" ? "10px" : "13px",
          height: size === "small" ? "10px" : "13px",
          zIndex: 2,
        }}
      />
    </Box>
  )
}

const NetworkProviderInnerIcon = ({ walletIcon, size }: { walletIcon: string; size: "small" | "large" }) => {
  return (
    <img
      src={walletIcon}
      alt="wallet icon"
      style={{
        width: size === "small" ? "22px" : "35px",
        height: size === "small" ? "22px" : "35px",
        zIndex: 2,
      }}
    />
  )
}

const icons = {
  ETHEREUM: "/images/tokens/eth.svg",
  MANTLE: "/images/tokens/mnt.svg",
  SOLANA: "/images/tokens/solana.svg",
}

type ChainName = keyof typeof icons

const getIconSrc = (chainName: ChainName) => {
  if (icons[chainName]) {
    return icons[chainName]
  }

  return "/images/warning.svg"
}
