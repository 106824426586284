import { DynamicContextProps } from "@dynamic-labs/sdk-react-core"
import { EthereumWalletConnectors } from "@dynamic-labs/ethereum"
import { SolanaWalletConnectors } from "@dynamic-labs/solana"

export const dynamicSettings: DynamicContextProps["settings"] = {
  environmentId: process.env.NEXT_PUBLIC_DYNAMIC_ENVIRONMENT_ID ?? "",
  walletConnectors: [EthereumWalletConnectors, SolanaWalletConnectors],
  initialAuthenticationMode: "connect-only",
  // Custom EVM networks
  evmNetworks: [
    {
      name: "Mantle",
      networkId: 5000,
      chainId: 5000,
      nativeCurrency: {
        name: "MNT",
        symbol: "MNT",
        decimals: 18,
      },
      rpcUrls: ["https://rpc.mantle.xyz"],
      blockExplorerUrls: ["https://explorer.mantle.xyz"],
      iconUrls: ["https://ondo.finance/images/logos/Mantle.svg"],
    },
  ],
}
