import Accordion from "@mui/material/Accordion/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary/AccordionSummary"
import Stack from "@mui/material/Stack/Stack"

import Typography from "@mui/material/Typography/Typography"
import Chip from "@mui/material/Chip/Chip"
import Switch from "@mui/material/Switch/Switch"
import Box from "@/components/Box"
import AccordionDetails from "@mui/material/AccordionDetails/AccordionDetails"
import { ArrowRight } from "../svgs/Svgs"

type Props = {
  name: string
  details: string
  enabled: boolean
  expanded: boolean
  onExpand: () => void
  onChange: (checked: boolean) => void
  alwaysOn?: boolean
}
export const CookieAccordion: React.FC<Props> = ({
  name,
  details,
  enabled,
  onExpand,
  expanded,
  onChange,
  alwaysOn,
}) => (
  <>
    <Accordion
      {...{ expanded, onChange: onExpand }}
      key={name}
      sx={{ padding: { xs: "0 4px", md: "0 20px" }, boxShadow: "unset" }}
    >
      <AccordionSummary sx={{ margin: 0, padding: 0 }}>
        <Stack
          spacing={0}
          sx={{
            flexGrow: 1,
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          gap={1}
          direction="row"
        >
          <Stack flex={3} flexDirection="row" justifyContent="center" alignItems="center">
            <Box
              sx={{
                width: "20px",
                height: "20px",
                marginBottom: "-5px",
                transition: "transform 0.3s ease-in-out",
                mr: 1,
                transform: expanded ? "rotate(90deg)" : "rotate(0deg)",
              }}
            >
              <ArrowRight />
            </Box>
            <Typography flex={2} variant="body1">
              {name}
            </Typography>
          </Stack>

          {alwaysOn ? (
            <Chip variant="outlined" label="Always Enabled" color="primary" />
          ) : (
            <Switch
              checked={enabled}
              size="medium"
              color="primary"
              onClick={(e) => e.stopPropagation()}
              onChange={(e, c) => onChange(c)}
            />
          )}
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Typography variant="body1" color="GrayText">
          {details}
        </Typography>
      </AccordionDetails>
    </Accordion>
  </>
)
