import { useState } from "react"

import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import Box from "@/components/Box"
import { ArrowDownward } from "@mui/icons-material"
import Collapse from "@mui/material/Collapse"
import { colors } from "../Providers/ThemeRegistry/theme"
import Stack from "@mui/material/Stack"
import Link from "next/link"
import { ClickAwayListener, styled } from "@mui/material"
import { NavLink } from "./NavLinks"
import { ExternalLink } from "../svgs/Svgs"

export const DesktopNavDropdownMenu: React.FC<{ label: string; subNavLinks: NavLink[] }> = ({ label, subNavLinks }) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleClose = () => {
    setIsOpen(false)
  }
  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box position="relative">
        <Button onClick={() => setIsOpen(!isOpen)} sx={{ px: 1.4, background: "inherit !important" }}>
          <Typography variant="body1" sx={{ lineHeight: "28px !important" }}>
            {label}
          </Typography>
          <ArrowDownward
            style={{
              height: "16px",
              transform: isOpen ? "rotate(180deg)" : "rotate(0)",
              transition: "transform 300ms linear",
            }}
          />
        </Button>
        <Collapse
          timeout={380}
          in={isOpen}
          sx={{
            position: "absolute",
            bottom: "-1px",
            left: 0,
            transform: "translateY(100%)",
            background: colors.base.white,
            border: `1px solid ${colors.base.greyLight}`,
            borderRadius: "12px",
            overflow: "hidden",
            boxShadow: `0px 2px 4px ${colors.base.greyLight}`,
            transitionDelay: "60ms",
          }}
        >
          <Stack justifyContent="center" alignItems="start" padding="12px">
            {subNavLinks.map((link) => (
              <StyledLink
                key={link.name}
                href={link.href}
                target={link.openInNewTab ? "_blank" : undefined}
                onClick={handleClose}
              >
                <Typography sx={{ whiteSpace: "nowrap", mr: 0.2 }}>{link.name}</Typography>
                {link.showArrow && <ExternalLink />}
              </StyledLink>
            ))}
          </Stack>
        </Collapse>
      </Box>
    </ClickAwayListener>
  )
}

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: row;
  text-decoration: none;
  align-items: center;
  color: inherit;
  width: 100%;
  text-align: left;
  padding: 8px;
  border-radius: 8px;
  &:hover {
    background: ${colors.base.offWhite};
  }
`

export default DesktopNavDropdownMenu
