"use client"

import { DynamicWagmiConnector } from "@dynamic-labs/wagmi-connector"

import { DynamicContextProvider } from "@dynamic-labs/sdk-react-core"

import { dynamicSettings } from "./dynamic/settings"
import { WalletProvider } from "./WalletProvider"

export function WalletWrapper({ children }: { children: React.ReactNode }) {
  return (
    <DynamicContextProvider settings={dynamicSettings}>
      <DynamicWagmiConnector>
        <WalletProvider>{children}</WalletProvider>
      </DynamicWagmiConnector>
    </DynamicContextProvider>
  )
}
