import { ReqIntercept } from "./types"

const dynamicIntercepts: ReqIntercept[] = [
  {
    shouldIntercept: async (resource, config) => {
      return resource === "https://app.dynamicauth.com/api/v0/sdk/f5dfcc37-784e-4183-89b6-48ffac21ab13/connect"
    },
    intercept: async () => {
      console.debug("Blocked Dynamic.xyz connect event POST")
      return new Response(undefined, {
        status: 202,
      })
    },
  },
]

export default dynamicIntercepts
