import { useState } from "react"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import Box from "@/components/Box"
import { ArrowDownward } from "@mui/icons-material"
import Collapse from "@mui/material/Collapse"
import Stack from "@mui/material/Stack"
import Link from "next/link"
import { NavLink } from "./NavLinks"
import { ExternalLink } from "../svgs/Svgs"
import { styled } from "@mui/material"

type Props = {
  closeHeaderMenu?: () => void
}

export const MobileNavDropdownMenu: React.FC<{ label: string; subNavLinks: NavLink[] } & Props> = ({
  closeHeaderMenu,
  label,
  subNavLinks,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Box>
      <Button onClick={() => setIsOpen(!isOpen)} sx={{ padding: 0, "&:hover": { background: "inherit" } }}>
        <Typography variant="h6" sx={{ lineHeight: "28px !important" }}>
          {label}
        </Typography>
        <ArrowDownward
          style={{
            height: "16px",
            transform: isOpen ? "rotate(180deg)" : "rotate(0)",
            transition: "transform 300ms linear",
          }}
        />
      </Button>
      <Collapse in={isOpen} unmountOnExit>
        <Stack justifyContent="center" alignItems="start" padding="4px 0px 4px 24px">
          {subNavLinks.map((link) => (
            <StyledLink
              key={link.name}
              href={link.href}
              target={link.openInNewTab ? "_blank" : undefined}
              onClick={() => {
                closeHeaderMenu?.()
                setIsOpen(false)
              }}
            >
              <Typography sx={{ whiteSpace: "nowrap", mr: 0.2 }}>{link.name}</Typography>
              {link.showArrow && <ExternalLink />}
            </StyledLink>
          ))}
        </Stack>
      </Collapse>
    </Box>
  )
}

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: row;
  text-decoration: none;
  align-items: center;
  color: inherit;
  width: 100%;
  text-align: left;
  padding: 8px;
  border-radius: 8px;
`

export default MobileNavDropdownMenu
