"use client"
import Container from "@mui/material/Container/Container"
import { OndoFoundationLogo } from "./svgs/Svgs"
import { colors } from "./Providers/ThemeRegistry/theme"
import Box from "@/components/Box"
import Stack from "@mui/material/Stack/Stack"
import React from "react"
import { Grid, Typography } from "@mui/material"
import Link from "next/link"

const GlobalFooter: React.FC = () => {
  return (
    <Box bgcolor={colors.base.black} style={{}}>
      <Container
        maxWidth="xl"
        sx={{
          padding: { xs: "40px 24px 40px 24px", md: "107px 24px 40px 24px" },
        }}
      >
        <Stack sx={{ flexDirection: { xs: "column", md: "row" } }} justifyContent="space-between" gap="24px">
          <Box marginBottom="48px">
            <OndoFoundationLogo color="white" />
          </Box>
          <Grid container spacing={"48px"} maxWidth={800} style={{ width: "100%" }}>
            <Column
              columnTitle="Foundation"
              links={[
                {
                  name: "Blog",
                  href: "https://blog.ondo.foundation",
                  isExternalLink: true,
                },
                { name: "Docs", href: "https://docs.ondo.foundation" },
                { name: "About", href: "/about" },
              ]}
            />
            <Column
              columnTitle="Protocols"
              links={[
                {
                  name: "Flux Finance",
                  href: "https://fluxfinance.com/",
                  isExternalLink: true,
                },
              ]}
            />
            <Column columnTitle="Partners" links={[{ name: "Ondo Finance", href: "https://ondo.finance/" }]} />
            <Column
              columnTitle="Governance"
              links={[
                { name: "Ondo DAO", href: "https://docs.ondo.foundation/ondo-dao" },
                { name: "Ondo Token", href: "https://docs.ondo.foundation/ondo-token" },
                { name: "Vote", href: "https://www.tally.xyz/gov/ondo-dao" },
                { name: "Discuss", href: "https://forum.fluxfinance.com/" },
              ]}
            />
          </Grid>
        </Stack>
        <Typography color="white" marginTop="80px">
          Ondo Foundation © {new Date().getFullYear()}
        </Typography>
      </Container>
    </Box>
  )
}
type FooterLink = {
  name: string
  href: string
  isExternalLink?: boolean
}
const Column: React.FC<{ columnTitle: string; links: FooterLink[] }> = ({ columnTitle, links }) => {
  return (
    <Grid item xs={6} md={3}>
      <Stack gap="8px">
        <Typography variant="body1" color={colors.base.white}>
          {columnTitle}
        </Typography>
        {links.map(({ name, href, isExternalLink }) => (
          <Link
            key={name}
            href={href}
            target={isExternalLink ? "_blank" : ""}
            passHref
            style={{ textDecoration: "none" }}
          >
            <Typography variant="body1" color={colors.base.greyMid}>
              {name}
            </Typography>
          </Link>
        ))}
      </Stack>
    </Grid>
  )
}
export default GlobalFooter
