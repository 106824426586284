import { createContext, FC, PropsWithChildren, useContext } from "react"
import { breakpoints } from "@/components/Providers/ThemeRegistry/theme"
import { useWindowSize } from "@/utils/browser"

const defaultBreakpoints = {
  md: { down: false, up: true },
  sm: { down: false, up: true },
  xs: { down: false, up: true },
  lg: { down: false, up: true },
  xl: { down: false, up: true },
}
const BreakpointsContext = createContext(defaultBreakpoints)

type BreakpointsContextType = typeof defaultBreakpoints

// BreakpointsProvider renders `null` until breakpoints are available.
export const BreakpointsProvider: FC<PropsWithChildren> = ({ children }) => {
  const { width } = useWindowSize()
  const bps = Object.fromEntries(
    Object.entries(breakpoints).map(([k, v]) => [k, { down: width! <= v, up: width! > v }])
  )

  return (
    <BreakpointsContext.Provider value={bps as BreakpointsContextType}>
      {width === undefined ? null : children}
    </BreakpointsContext.Provider>
  )
}

// useBreakpoints will always return valid breakpoints.
export const useBreakpoints = () => useContext(BreakpointsContext)
